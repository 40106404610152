<template>
  <div class="content-section introduction">
    <div class="feature-intro">
      <h1>{{ tLabel("Crea Treni Report") }}</h1>
    </div>
    <v-form ref="createHandlingHeaderForm">
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <v-autocomplete :label="tLabel('Client')" v-model="handlingHeaderReportReq.filter.clientInfo" 
            :items="owners"  item-text="code" return-object />
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :label="tLabel('Trace')"
              :items="listTracce"
              clearable
              v-model="handlingHeaderReportReq.filter.tracciaId"
              :item-text="traceCodeRender"
              return-object
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <DateTimeAdapter :label="tLabelRequired('Date from')" v-model.trim="startTime" :rules="[rules.required]" defaultTime="00:00:00" />
          </v-col>
          <v-col cols="6">
            <DateTimeAdapter :label="tLabelRequired('Date to')" v-model.trim="endTime" :rules="[rules.required]" defaultTime="23:59:59" />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-container>
      <v-row>
        <v-col cols="12" style="text-align: right">
          <v-btn color="success" class="pa-2 ma-2" @click="save" medium>Salva</v-btn>
          <v-btn color="error" class="pa-2 ma-2" @click="discard" medium>Annulla</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import i18n from "../../i18n";
import FormatsMixins from "../../mixins/FormatsMixins";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";
import moment from "moment";

export default {
  name: "CreateHandlingHeader",
  title: i18n.t("Create Handling Header"),
  data() {
    return {
      handlingTypes: [],
      handlingHeaderReportReq: {
        type: "TRAIN",
        filter: {},
      },
      startTime: null,
      endTime: null,
      tracciaId: null,
      listTracce: [],
      owners: [],
    };
  },
  async mounted() {
    this.handlingTypes = await this.$api.yardData.getHandlingTypes();
    this.listTracce=await this.$api.railData.listTracce();
    this.owners= await this.$api.get(this.$apiConfiguration.BASE_PATH_YARD + "companies/owners");
    console.log("handlingTypes", this.handlingTypes);
    console.log("tracce", this.listTracce);
  },
  methods: {
    async save() {
      const valid = this.$refs.createHandlingHeaderForm.validate();
      if (!valid) {
        return;
      }

      this.handlingHeaderReportReq.filter.startTime = JSON.parse(JSON.stringify(moment(this.startTime).format("YYYY-MM-DDTHH:mm:ss")));
      this.handlingHeaderReportReq.filter.endTime = JSON.parse(JSON.stringify(moment(this.endTime).format("YYYY-MM-DDTHH:mm:ss")));
      console.log("save handlingHeader", this.handlingHeaderReportReq);
      await this.$api.reportData.report(this.handlingHeaderReportReq);
      this.$router.go(-1);
    },
    discard() {
      console.log("discard handlingHeader", this.handlingHeaderReportReq);
      this.$router.go(-1);
    },
    traceCodeRender: item => item.numero + ' - '  + ' - ' + item.nodoPartenza + ' - ' + item.nodoArrivo,
  },
  mixins: [FormatsMixins],
  components: {
    DateTimeAdapter,
  },

  
};
</script>
